$trans: all .25s ease;

$red_text: #d50000;

@media (max-width: 1920px) {

  body {
    overflow-x: hidden;
  }
}

// Большие девайсы (большие десктопы, < 1200px)
@media (max-width: 1199.98px) {

  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -14px, 0) rotate(-270deg);
  }

  .hamburger {
    position: absolute;
    height: 18px;
    right: 30px;
    padding: 0;
    outline: none !important;
  }

  .hamburger-box {
    width: 22px;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    height: 3px;
    border-radius: 3px;
    width: 22px;
    background-color: #fff;
  }

  .hamburger--elastic .hamburger-inner::before {
    top: 7px;
  }

  .hamburger--elastic .hamburger-inner::after {
    top: 14px;
  }

  .mobile_header {
    display: block;
    background: url("../img/top_header_bg.jpg") no-repeat center;

    .col_left {

      .main_logo img {
        height: 70px;
      }
    }

    .col_right {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .bottom_header .col_center {
    display: none;
  }

  .desktop_header {
    display: none;
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    flex-direction: column;
    background: #240D13;
    padding-bottom: 20px;
  }

  .top_header {
    background: transparent;
    box-shadow: none;
  }

  .bottom_header .line_header {
    display: none;
  }

  .bottom_header {
    background: transparent;
  }

  .bottom_header .col_left ul {
    flex-direction: column;
    align-items: center;

    li {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  .top_header {
    order: 2;
  }

  .bottom_header .red_btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .top_header .col_left {
    height: auto;
    justify-content: center;
    margin-bottom: 10px;
  }

  .top_header .col_right {
    height: auto;
    justify-content: center;
  }

  .main_screen h1 {
    font-size: 30px;
  }

  .anchors_games_csgo {
    flex-direction: column;
  }

  .anchors_games_csgo a:nth-of-type(3):after {
    display: none;
  }

  .anchors_games_csgo a:nth-of-type(3) {
    margin-top: 0;
  }

  .anchors_games_csgo a {
    margin-bottom: 20px;
  }

  .main_screen h2 {
    font-size: 28px;
  }

  .main_screen h4 {
    padding-left: 143px;
    font-size: 20px;
  }

  .training h2 {
    font-size: 28px;
  }

  .video1 h2 {
    font-size: 28px;
  }

  .video1 {
    padding-top: 50px;
  }

  .gallery h2 {
    font-size: 28px;
  }

  .top_leader h2,
  .sport h2 {
    font-size: 28px;
  }

  .our_computers h2 {
    font-size: 28px;
  }

  .reviews h2 {
    font-size: 28px;
  }

  .reviews_slider .next_arrow {
    right: 280px;
  }

  .reviews_slider .prev_arrow {
    left: 280px;
  }

  .accessories h2 {
    font-size: 28px;
  }

  .about h2 {
    font-size: 28px;
  }

  .reservation h2 {
    font-size: 28px;
  }

  .anchors_games_csgo {
    margin-bottom: 130px;
  }

  .video1 {
    height: 830px;
  }

                                        //RESERVATION PAGE

  .content h1 {
    font-size: 30px;
  }

  .content h3 {
    font-size: 22px;
  }

  .reservation_main_info h5 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .reservation_main_info p {
    margin-bottom: 0;
    text-align: center;
  }

  .reservation_main_info .info {
    padding-left: 0;
  }

  .reservation_main_info .info:before {
    left: 50%;
    top: -30px;
    margin-left: -6px;
  }

  .content h3 {
    text-align: center;
  }

  .reservation_included p {
    text-align: center;
  }

  .reservation_included ul,
  .footnotes ul {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
  }

  .schedule {
    max-width: 555px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .samples {
    max-width: 555px;
    margin-left: auto;
    margin-right: auto;
  }

  .more_about_anchors {
    flex-direction: column;

    a {
      margin-bottom: 15px;
    }
  }

                                                //FAQ PAGE

  .more_about_anchors {
    align-items: center;
  }

  .faq_page .more_about h4 {
    font-size: 28px;
  }

  .faq_accordeon .question {
    font-size: 16px;
  }

  .faq_accordeon .answer h5 {
    font-size: 18px;
  }

  .faq_accordeon .answer h6 {
    font-size: 14px;
  }

  .faq_accordeon .answer ol li {
    font-size: 14px;
  }

  .faq_accordeon .answer p {
    font-size: 14px;
  }

                                                //ABOUT PAGE
  .about_page h4 {
    font-size: 22px;
  }

  .requisites {
    background: none;
  }

  .requisites ul {
    max-width: 100%;
  }

  .about_page .more_about h4 {
    font-size: 28px;
  }

  .forparents .more_about h4 {
    font-size: 30px;
  }

  .content h3 {
    font-size: 22px;
  }

  .ps {
    padding-right: 264px;
    background: url("../img/ps_bg.png") no-repeat center left;
  }

  .new_building h2 {
    font-size: 28px;
  }

  .new_building .new_building_row:before {
    display: none;
  }
}

// Средние девайсы («таблетки», < 992px)
@media (max-width: 991.98px) {

  .main_screen {
    padding-top: 100px;
  }

  .training_row2 {
    display: none;
  }

  .reviews_slider .next_arrow {
    right: 180px;
  }

  .reviews_slider .prev_arrow {
    left: 180px;
  }

  .training_row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 90px;
  }

  .training_row1 img {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }

  .video1 {
    height: 730px;
    padding-top: 70px;
  }

  .gallery_shadow {
    display: none;
  }

  .top_leader {
    padding-bottom: 160px;
  }

  .sport_right_img,
  .sport_left_img,
  .accessories .accessories_phonix,
  .reservation .reservation_left_img,
  .reservation .reservation_right_img,
  .video1_bg_left,
  .video1_bg_right {
    display: none;
  }

  .our_computers > .container {
    background-size: contain;
  }

  .sport {
    padding-bottom: 90px;
  }

  .bottom_header .red_btn {
    height: 54px;
    width: 224px;
    font-size: 18px;
  }

  .reservation .red_btn {
    width: 260px;
    font-size: 18px;
    height: 54px;
  }

  .reservation .grey_text {
    margin-top: 60px;
  }

  .forparents h3 {
    line-height: 40px;
  }

  .ps {
    padding-right: 30px;
    padding-left: 56px;
  }

  .forparents li {
    padding-left: 56px;
  }

  .new_building img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

// Малые девайсы («ландшафтные», < 768px)
@media (max-width: 767.98px) {

  .main_screen h1 {
    font-size: 26px;
    line-height: 26px;
  }

  .main_screen h2,
  .training h2,
  .video1 h2,
  .gallery h2,
  .top_leader h2,
  .sport h2,
  .our_computers h2,
  .reviews h2,
  .accessories h2,
  .about h2,
  .reservation h2 {
    font-size: 24px;
  }

  .main_screen h4 {
    font-size: 18px;
  }

  .main_screen p,
  .training p,
  .gallery p,
  .top_leader p,
  .sport p,
  .our_computers p,
  .reviews p,
  .accessories p,
  .about_item p,
  .reservation p {
    font-size: 14px;
    line-height: 22px;
  }

  .reservation .grey_text {
    line-height: 18px;
  }

  .reviews p span {
    font-size: 22px;
  }

  .accessories {
    background: none;
  }

  .video1 {
    height: 640px;
  }

  .our_computers img {
    max-width: 250px;
  }

  .accessories {
    padding-top: 70px;
  }

  .accessories_zoom {
    max-width: 300px;

    img:first-of-type {
      max-width: 300px;
    }
  }

  .one_quote img {
    max-width: 40px;
  }

  .reviews_slider .next_arrow {
    right: 110px;
  }

  .reviews_slider .prev_arrow {
    left: 110px;
  }

  .sport {
    padding-bottom: 40px;
  }

                          //RESERVATION PAGE

  .content h1 {
    font-size: 26px;
    line-height: 26px;
  }

  .content h3 {
    font-size: 20px;
  }

  .reservation_main_info h5 {
    font-size: 16px;
    line-height: 26px;
  }

  .reservation_main_info p {
    font-size: 14px;
    line-height: 22px;
  }

  .reservation_included ul li {
    font-size: 14px;
    line-height: 22px;
  }

  .schedule .table_row {
    font-size: 14px;
  }

  .schedule h4 {
    font-size: 22px;
  }

                                                  //FAQ PAGE

  .faq_page .more_about h4 {
    font-size: 26px;
    line-height: 26px;
  }

  .faq_accordeon .question {
    font-size: 14px;
  }

  .faq_accordeon .question i {
    font-size: 28px;
  }

  .faq_accordeon > ol > li:before {
    font-size: 18px;
    height: 30px;
    width: 30px;
    top: 24px;
  }

  .faq_accordeon .answer h5 {
    font-size: 16px;
  }

  .faq_accordeon .question {
    height: 80px;
  }

                                              //ABOUT PAGE
  .about_page h4 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 38px;
  }

  .about_page p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  .about_page h4 {
    text-align: center;
  }

  .requisites li {
    margin-bottom: 15px;
    font-size: 14px;
    text-align: center;
  }

  .about_page .more_about h4 {
    font-size: 24px;
    line-height: 26px;
  }

                                              //FORPERENTS PAGE
  .forparents li {
    font-size: 14px;
    line-height: 22px;
  }

  .ps {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    background: url("../img/ps_bg.png") no-repeat center left;
  }

  .ps h5 {
    font-size: 18px;
  }

  .ps p {
    font-size: 14px;
    line-height: 22px;
  }

  .ps:before {
    top: 79px;
  }

  .forparents .more_about h4 {
    font-size: 26px;
  }

  .new_building h2 {
    font-size: 24px;
  }

  .new_building p {
    font-size: 14px;
    line-height: 22px;
  }
}

// Экстрамалые девайсы («телефоны», < 576px)
@media (max-width: 575.98px) {

  .main_screen h1 {
    font-size: 22px;
  }

  .main_screen h2, .training h2, .video1 h2, .gallery h2,
  .top_leader h2, .sport h2, .our_computers h2, .reviews h2, .accessories h2,
  .about h2, .reservation h2 {
    font-size: 20px;
  }

  .main_screen h4 {
    font-size: 16px;
    padding-left: 0;
    text-align: center;
  }

  .main_screen h4 span {
    position: relative;
  }

  .training_row1 img {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .video1 {
    height: auto;
    padding-top: 0;
    background: none;
    margin-bottom: 60px;
  }

  .video1 h2 {
    margin-bottom: 58px;
  }

  .top_leader .slick-slide img {
    max-width: 200px;
  }

  .top_leader_slider .slick-slide {
    padding-left: 0;
    padding-top: 0;
  }

  .top_leader_slider .slick-slide {
    display: flex;
    justify-content: center;
    width: 300px !important;
  }

  .top_leader_slider {
    width: 300px;
  }

  .top_leader_slider {
    background: none;
  }

  .top_leader_slider .slick-dots {
    padding-left: 0;
    bottom: 28px;
  }

  .top_leader_slider .prev_arrow {
    bottom: 30px;
  }

  .top_leader_slider .next_arrow {
    bottom: 25px;
    right: 64px;
  }

  .top_leader {
    padding-bottom: 40px;
  }

  .sport {
    padding-bottom: 40px;
  }

  .reviews_slider .next_arrow {
    right: -40px;
  }

  .reviews_slider .prev_arrow {
    left: -40px;
  }

  .accessories {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .about_item {
    margin-bottom: 50px;
  }

  .reservation .line_footer {
    margin-bottom: 70px;
  }

  .top_header .col_left {
    display: flex;
    flex-direction: column;
  }

  .top_header .col_right {
    display: flex;
    flex-direction: column;
  }

  a.header_vk {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .top_header .col_right span a {
    display: block;
  }

  .gallery_row img {
    margin-left: auto;
    margin-right: auto;
  }

  .gallery_row, .about_slider, .training_row1 {

    .slick-dots {
      bottom: -20px;
    }

    .slick-dots li {
      margin-left: 0;
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .slick-dots li button {
      height: 14px;
      width: 14px;
      margin-left: auto;
      margin-right: auto;
      border: 2px solid $red_text;
      border-radius: 50%;
      background-color: transparent;

      &:before {
        display: none;
      }
    }

    .slick-dots li.slick-active button {
      background-color: $red_text;
    }
  }

  .training h2 {
    margin-top: 80px;
  }

  .reviews_slider .next_arrow {
    right: 10px;
  }

  .reviews_slider .prev_arrow {
    left: 10px;
  }


                                      //RESERVATION PAGE

  .content {
    padding-bottom: 51px;
  }

  .content h1 {
    font-size: 22px;
  }

  .content h3 {
    margin-bottom: 32px;
    font-size: 16px;
  }

  .reservation_main_info h5 {
    font-size: 14px;
  }

  .reservation_included p {
    font-size: 14px;
    line-height: 22px;
  }

  .schedule {
    padding-top: 36px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .schedule h4 {
    font-size: 18px;
    text-align: center;
  }

  .more_about h4 {
    font-size: 18px;
    margin-bottom: 50px;
  }

  .schedule .table_row {
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
    border-bottom: 1px solid rgba(255,255,255,.1);
  }

  .schedule .table_row .table_col {
    height: 30px;
    width: 100% !important;
    justify-content: center;
    border-bottom: 0;
  }

  .schedule a {
    font-size: 14px;
  }

  .footnotes ul li {
    line-height: 22px;
  }

  .samples {
    flex-direction: column;
    align-items: center;
  }

  a.sample {
    margin-bottom: 20px;
  }

  .more_about {
    padding-top: 90px;
  }

                                                //FAQ PAGE

  .faq_page .more_about h4 {
    font-size: 20px;
  }

  .faq_accordeon .question {
    padding-left: 40px;
    font-size: 14px;
    text-transform: none;
  }

  .faq_accordeon > ol > li:before {
    height: 25px;
    width: 25px;
    font-size: 14px;
    top: 26px;
  }

  .faq_accordeon .answer {
    padding-left: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .faq_accordeon .answer ol li {
    margin-bottom: 10px;
  }

  .faq_accordeon .answer h5 {
    font-size: 14px;
    line-height: 20px;
  }

  .faq_accordeon .answer h6 {
    margin-bottom: 15px;
  }

  .faq_accordeon {
    margin-top: -120px;
  }

  .faq_page .more_about {
    padding-top: 60px;
  }

                                              //ABOUT PAGE

  .about_page h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .requisites {
    margin-top: 50px;
  }

  .requisites .line_footer {
    margin-bottom: 54px;
  }

  .about_page h4 {
    margin-bottom: 30px;
  }

  .about_page .more_about h4 {
    font-size: 20px;
  }

  .forparents .more_about h4 {
    font-size: 20px;
  }

  .content h3 {
    font-size: 14px;
    line-height: 30px;
  }

  .forparents li:before {
    font-size: 18px;
    height: 28px;
    width: 28px;
  }

  .forparents li {
    padding-left: 48px;
  }

  .new_building h2 {
    font-size: 20px;
  }

  .new_building {
    padding-top: 60px;
  }
}